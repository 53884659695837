import { Configuration } from '@azure/msal-browser'

export const allowedTenants: string[] = ['72f988bf-86f1-41af-91ab-2d7cd011db47']

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
    // Sign in users of a specific organization only.
    authority: `https://login.microsoftonline.com/organizations/`,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

export const authRequest = {
  scopes: [`api://${msalConfig.auth.clientId}/IEP.Api`, 'email', 'profile'],
}

export const graphScopes = ['user.read', 'user.readbasic.all']

export enum AppRoles {
  EscalationDev = 'EscalationDev',
  EscalationAdministrator = 'EscalationAdministrator',
  HITGeneralAgent = 'HITGeneralAgent',
  HITSuperAgent = 'HITSuperAgent',
}
