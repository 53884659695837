import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser'
import { MessageBarType } from '@fluentui/react'
import { authRequest } from '../auth/authConfig'
import {
  Api,
  CaseEntity,
  CasePatchForm,
  EscalationRequestForm,
  NoteEntity,
  ProblemDetails,
} from './escalationApi'
import {
  BaseEvents,
  EventEmitter,
  NotificationEnum,
} from '../eventEmitter/eventEmitter'
import {
  IEscalationApiService,
  IGetCaseParams,
  IGetCasesParams,
  IGetEscalationParams,
  IGetEscalationsParams,
  IGetEscalationStatusPageEntityParams,
  IGetEscalationDetailParams,
  IGetCaseDetailParams,
  IGetHistoryNotesParams,
} from './IEscalationApiService'
import {
  CaseEntityModel,
  EscalationModel,
  EscalationStatusPageModel,
  EscalationDetailPageModel,
  CaseDetailPageModel,
  PagedDataModel,
  NoteEntityModel,
} from '../models'

export default class EscalationApiService implements IEscalationApiService {
  escalationApi: Api<unknown>
  private publicClientApplication!: PublicClientApplication
  private eventEmitter!: EventEmitter<BaseEvents>

  constructor() {
    this.escalationApi = new Api({
      baseUrl: process.env.REACT_APP_API_ENDPOINT!,
    })
  }

  public initialize(
    publicClientApplication: PublicClientApplication,
    eventEmitter: EventEmitter<BaseEvents>
  ) {
    this.publicClientApplication = publicClientApplication
    this.eventEmitter = eventEmitter
  }

  // try to get access token from MSAL's internal cache
  private async getAccessToken(): Promise<string> {
    const activeAccount = this.publicClientApplication.getActiveAccount()
    let accessToken = ''

    try {
      const response = await this.publicClientApplication.acquireTokenSilent({
        ...authRequest,
        account: activeAccount!,
      })

      accessToken = response.accessToken
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // call acquireTokenPopup in case of acquireTokenSilent failure
        // due to consent or interaction required
        if (
          error.errorCode === 'consent_required' ||
          error.errorCode === 'interaction_required' ||
          error.errorCode === 'login_required'
        ) {
          const response = await this.publicClientApplication.acquireTokenPopup(
            {
              ...authRequest,
              account: activeAccount!,
            }
          )
          accessToken = response.accessToken
        }
      }
    }

    return accessToken
  }

  private handleException(error: any) {
    let message = ''
    if (error as ProblemDetails) {
      const problem = error as ProblemDetails
      if (problem.status !== undefined) {
        if (problem.status === 401) {
          message = 'unauthorized'
        } else if (problem.status !== 404) {
          if (problem.error as Error) {
            const innerError = problem.error 
            message = innerError
          }
        }
      }
    } else if (typeof error === 'string') {
      message = error
    } else {
      message = 'unexpected error'
    }

    if (message) {
      this.eventEmitter.emit(
        NotificationEnum[NotificationEnum.globalNotification],
        message,
        MessageBarType.error
      )
    }
  }

  public async getEscalation(
    params: IGetEscalationParams
  ): Promise<EscalationModel | undefined> {
    console.debug(
      `=> send a http GET call to /api/escalation/{id} with params: ${JSON.stringify(
        params
      )}`
    )

    let result: EscalationModel | undefined

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.escalationDetail(
        params.id,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )
      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)
      const data = httpResponse.data

      if (data) {
        result = {
          ...data,
          key: data.escalationId,
        }
      }
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async getEscalations(
    params: IGetEscalationsParams
  ): Promise<EscalationModel[]> {
    console.debug(
      `=> send a http GET call to /api/escalation with params: ${JSON.stringify(
        params
      )}`
    )

    let result: EscalationModel[] = []

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.escalationList(
        {
          UserAlias: params.userAlias,
          Status: params.status,
          RequestType: params.requestType,
          TeamName: params.teamName,
          Count: params.count,
          Offset: params.offset,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )
      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)
      const data = httpResponse.data

      for (let i = 0; i < data.length; i++) {
        result.push({
          ...data[i],
          key: data[i].escalationId,
        })
      }
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async getEscalationStatusPageEntities(
    params: IGetEscalationStatusPageEntityParams
  ): Promise<PagedDataModel<EscalationStatusPageModel>> {
    console.debug(
      `=> send a http GET call to /api/escalation/get-status-page-entities/{escalationStatusPage} with params: ${JSON.stringify(
        params
      )}`
    )

    let result: PagedDataModel<EscalationStatusPageModel> = {
      data: [],
      totalCount: 0,
    }

    let entities: EscalationStatusPageModel[] = []
    let totalCount = 0

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse =
        await this.escalationApi.api.escalationGetStatusPageEntitiesDetail(
          params.escalationStatusPage,
          {
            userAlias: params.userAlias,
            offset: params.offset,
            count: params.count,
            escalationId: params.escalationId,
          },
          {
            headers: {
              Authorization: 'Bearer ' + accessToken,
            },
          }
        )
      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)
      const responseData = httpResponse.data.data

      if (responseData) {
        entities = responseData
      }
      if (httpResponse.data.totalCount !== undefined) {
        totalCount = httpResponse.data.totalCount
      }
    } catch (error) {
      this.handleException(error)
    }

    for (let i = 0; i < entities.length; i++) {
      result.data.push({
        ...entities[i],
        key: entities[i].escalationId,
      })
    }
    result.totalCount = totalCount

    return result
  }

  public async getCase(
    params: IGetCaseParams
  ): Promise<CaseEntityModel | undefined> {
    console.debug(
      `=> send a http GET call to /api/case/{caseId} with params: ${JSON.stringify(
        params
      )}`
    )

    let result: CaseEntityModel | undefined

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.caseDetail(
        params.caseId,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )

      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)
      const data = httpResponse.data

      if (data) {
        result = {
          ...data,
          key: data.id,
        }
      }
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async getCases(
    params: IGetCasesParams
  ): Promise<PagedDataModel<CaseEntityModel>> {
    console.debug(
      `=> send a http GET call to /api/case with params: ${JSON.stringify(
        params
      )}`
    )

    let result: PagedDataModel<CaseEntityModel> = {
      data: [],
      totalCount: 0,
    }

    let entities: CaseEntity[] = []
    let totalCount = 0

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.caseList(
        {
          Count: params.count,
          Offset: params.offset,
          ResolvedDateRangeStart: params.resolvedDateRangeStart
            ? params.resolvedDateRangeStart.toISOString()
            : undefined,
          ResolvedDateRangeEnd: params.resolvedDateRangeEnd
            ? params.resolvedDateRangeEnd.toISOString()
            : undefined,
          AssignedTo: params.assignedTo,
          CaseStatus: params.caseStatus,
          InitialRiskDecision: params.initialRiskDecision,
          CurrentRiskDecision: params.currentRiskDecision,
          EscalationId: params.escalationId,
          RiskEvaluationId: params.riskEvaluationId,
          ActionServiceRequestId: params.actionServiceRequestId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )
      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)
      const responseData = httpResponse.data.data

      if (responseData) {
        entities = responseData
      }
      if (httpResponse.data.totalCount !== undefined) {
        totalCount = httpResponse.data.totalCount
      }
    } catch (error) {
      this.handleException(error)
    }

    for (let i = 0; i < entities.length; i++) {
      result.data.push({
        ...entities[i],
        key: entities[i].id,
      })
    }
    result.totalCount = totalCount

    return result
  }

  public async getEscalationDetail(
    params: IGetEscalationDetailParams
  ): Promise<PagedDataModel<EscalationDetailPageModel>> {
    console.debug(
      `=> send a http GET call to /api/escalation/{escalationId}/{caseStatusPage} with params: ${JSON.stringify(
        params
      )}`
    )

    let result: PagedDataModel<EscalationDetailPageModel> = {
      data: [],
      totalCount: 0,
    }

    let entities: EscalationDetailPageModel[] = []
    let totalCount = 0

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse =
        await this.escalationApi.api.escalationDetailByCaseStatusPage(
          params.escalationId,
          params.caseStatusPage,
          {
            caseId: params.caseId,
            count: params.count,
            offset: params.offset,
          },
          {
            headers: {
              Authorization: 'Bearer ' + accessToken,
            },
          }
        )

      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)
      const responseData = httpResponse.data.data

      if (responseData) {
        entities = responseData
      }
      if (httpResponse.data.totalCount !== undefined) {
        totalCount = httpResponse.data.totalCount
      }
    } catch (error) {
      this.handleException(error)
    }

    for (let i = 0; i < entities.length; i++) {
      result.data.push({
        ...entities[i],
        key: `${entities[i].caseId}_${entities[i].targetId}`,
      })
    }
    result.totalCount = totalCount

    return result
  }

  public async getEscalationDownloadDetail(
    params: IGetEscalationDetailParams
  ): Promise<EscalationDetailPageModel[]> {
    console.debug(
      `=> send a http GET call to /api/escalation/download/{escalationId}/{caseStatusPage} with params: ${JSON.stringify(
        params
      )}`
    )

    let result: EscalationDetailPageModel[] = []

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse =
        await this.escalationApi.api.escalationDownloadDetail(
          params.escalationId,
          params.caseStatusPage,
          {
            headers: {
              Authorization: 'Bearer ' + accessToken,
            },
          }
        )

      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)
      const data = httpResponse.data

      for (let i = 0; i < data.length; i++) {
        result.push({
          ...data[i],
          key: `${data[i].caseId}_${data[i].targetId}`,
        })
      }
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async getCaseDetail(
    params: IGetCaseDetailParams
  ): Promise<CaseDetailPageModel | undefined> {
    console.debug(
      `=> send a http GET call to /api/case/{caseId}/detail with params: ${JSON.stringify(
        params
      )}`
    )

    let result: CaseDetailPageModel | undefined

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.caseDetailByCaseId(
        params.caseId,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )
      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)
      const data = httpResponse.data

      if (data) {
        result = {
          ...data,
          key: `${data.caseId}`,
        }
      }
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  // CaseAuditController related api calls
  public async getHistoryNotes(
    params: IGetHistoryNotesParams
  ): Promise<NoteEntityModel[]> {
    console.debug(
      `=> send a http GET call to /api/case-audit/get-notes with params: ${JSON.stringify(
        params
      )}`
    )

    let result: NoteEntityModel[] = []
    let data: NoteEntity[] = []

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.caseAuditGetNotesList(
        {
          caseId: params.caseId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )
      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)
      data = httpResponse.data

      for (let i = 0; i < data.length; i++) {
        result.push({
          ...data[i],
          key: `${data[i].caseId}_${data[i].providerName}_${data[i].auditDate}_${data[i].note}`,
        })
      }
    } catch (error) {
      if (error instanceof Response) {
        if (error.status === 404) {
          console.log('404 not found')
        }
      } else if (typeof error === 'string') {
        console.log(error)
      } else {
        console.log('unexpected error')
      }
    }

    return result
  }

  public async patchCase(payload: CasePatchForm): Promise<boolean> {
    console.debug(
      `=> send a http PATCH call to /api/case/{caseId} with payload: ${JSON.stringify(
        payload
      )}`
    )

    let result = false

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.casePartialUpdate(
        payload.id,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )

      console.debug(`Got data from api: ${JSON.stringify(httpResponse.data)}`)

      if (httpResponse.status === 200) result = true
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async patchCases(payload: CasePatchForm[]): Promise<boolean> {
    console.debug(
      `=> send a http PATCH call to /api/case with payload: ${JSON.stringify(
        payload
      )}`
    )

    let result = false

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.casesPartialUpdate(
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )

      if (httpResponse.status === 200) result = true
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async postCasesToActionService(payload: string[]): Promise<boolean> {
    console.debug(
      `=> send a http POST call to /api/case/send-to-risk with params: ${JSON.stringify(
        payload
      )}`
    )

    let result = false

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.caseSendToRiskCreate(
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )

      console.debug(
        `Got status from /api/case/send-to-risk: ${JSON.stringify(
          httpResponse.status
        )}`
      )

      if (httpResponse.status === 201) result = true
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async postCasesToReenrich(payload: string[]): Promise<boolean> {
    console.debug(
      `=> send a http POST call to /api/enrichment/send-reenrich-to-servicebus with params: ${JSON.stringify(
        payload
      )}`
    )

    let result = false

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse =
        await this.escalationApi.api.enrichmentSendReenrichToServicebusCreate(
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + accessToken,
            },
          }
        )

      console.debug(
        `Got status from /api/enrichment/send-reenrich-to-servicebus: ${JSON.stringify(
          httpResponse.status
        )}`
      )

      if (httpResponse.status === 201) result = true
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async postAllCasesToActionService(
    payload: string[]
  ): Promise<boolean> {
    console.debug(
      `=> send a http POST call to /api/case/send-all-to-risk with params: ${JSON.stringify(
        payload
      )}`
    )

    let result = false

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.caseSendAllToRiskCreate(
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )

      console.debug(
        `Got status from /api/case/send-all-to-risk: ${JSON.stringify(
          httpResponse.status
        )}`
      )

      if (httpResponse.status === 201) result = true
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async postAllCasesBackToAgent(payload: string[]): Promise<boolean> {
    console.debug(
      `=> send a http POST call to /api/case/return-to-agent with params: ${JSON.stringify(
        payload
      )}`
    )

    let result = false

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.caseReturnToAgentCreate(
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )

      console.debug(
        `Got status from /api/case/return-to-agent: ${JSON.stringify(
          httpResponse.status
        )}`
      )

      if (httpResponse.status === 201) return true
    } catch (error) {
      this.handleException(error)
    }

    return result
  }

  public async postEscalation(
    payload: EscalationRequestForm
  ): Promise<boolean> {
    console.debug(
      `=> send a http POST call to /api/escalation/create-escalation with payload: ${JSON.stringify(
        payload
      )}`
    )

    let result = false

    const accessToken = await this.getAccessToken()
    try {
      const httpResponse = await this.escalationApi.api.createEscalation(
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      )

      console.debug(
        `Got status from /api/escalation/create-escalation: ${JSON.stringify(
          httpResponse.status
        )}`
      )

      if (httpResponse.status === 201) result = true
    } catch (error) {
      this.handleException(error)
    }

    return result
  }
}
