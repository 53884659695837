import * as React from 'react'
import { createContext } from 'react'
import { BaseEvents, EventEmitter } from './eventEmitter'

export const EventEmitterContext = createContext<EventEmitter<any>>(null as any)

export interface IEventEmitterProviderProps {
  instance: EventEmitter<BaseEvents>
}

const EventEmitterProvider: React.FunctionComponent<
  IEventEmitterProviderProps
> = ({ children, ...props }) => {
  return (
    <EventEmitterContext.Provider value={props.instance}>
      {children}
    </EventEmitterContext.Provider>
  )
}

export default EventEmitterProvider
