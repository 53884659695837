import {
  EventType,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { ThemeProvider, mergeStyles } from '@fluentui/react'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { M365ActualLightTheme } from '@m365-admin/customizations'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { msalConfig } from './auth/authConfig'
import AppContextProvider from './context/appContext'
import { EventEmitter } from './eventEmitter/eventEmitter'
import EventEmitterProvider from './eventEmitter/eventEmitterProvider'
import reportWebVitals from './reportWebVitals'
import EscalationApiServiceFactory from './services/escalationApiServiceFactory'
import GraphService from './services/graphService'
import Decommission from './Decommission'

var reactPlugin = new ReactPlugin()
var appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
})
appInsights.loadAppInsights()

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
})

initializeIcons()

const eventEmitter = new EventEmitter()

const msalInstance = new PublicClientApplication(msalConfig)

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    const payload = event.payload as RedirectRequest
    if (payload) {
      const account = payload.account
      if (account) {
        msalInstance.setActiveAccount(account)
      }
    }

    if (msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
    }
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log(JSON.stringify(event))
  }
})

GraphService.initializate(msalInstance, eventEmitter)
EscalationApiServiceFactory.initializate(msalInstance, eventEmitter)

ReactDOM.render(
  <ThemeProvider theme={M365ActualLightTheme}>
    <BrowserRouter>
      <AppContextProvider>
        <MsalProvider instance={msalInstance}>
          <EventEmitterProvider instance={eventEmitter}>
            <AppInsightsContext.Provider value={reactPlugin}>
              <Decommission />
            </AppInsightsContext.Provider>
          </EventEmitterProvider>
        </MsalProvider>
      </AppContextProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
