/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum ActionStatus {
  Pending = 'Pending',
  Completed = 'Completed',
}

export interface AssetIdEnrichmentRecord {
  /** @format date-time */
  txEventDate?: string | null
  assetId?: string | null
  riskEvaluationId?: string | null
  s_MCAPIAccountId?: string | null
  s_MCAPIOrganizationId?: string | null
  mcapiBillingAccountId?: string | null
  s_MCAPIUnifiedId?: string | null
  s_UnifiedAccountId?: string | null
  s_AssetId_1?: string | null
  s_PartnerName?: string | null
  s_Client?: string | null
  s_Entry?: string | null
  i_RiskModelScore?: string | null
  s_FinalResultCode?: string | null
  s_FinalReasonCode?: string | null
  s_OrderType?: string | null
  b_ClassicControlGroupFlag?: string | null
  i_Account_IP_country_mismatch?: string | null
  i_Account_IP_PI_country_mismatch?: string | null
  i_Account_PI_country_mismatch?: string | null
  i_BIN_Account_country_mismatch?: string | null
  s_AccountCountryCode?: string | null
  s_DeviceIPCountryCode?: string | null
  s_PaymentInstrumentIssueCountry?: string | null
  i_AccountAge?: string | null
  s_AccountEmailShortPattern?: string | null
  i_AccountEmailHandle_Length?: string | null
  s_AccountEmail_pii?: string | null
  s_AccountEmailDomain?: string | null
  s_AltEmail_pii?: string | null
  s_AltEmailDomain?: string | null
  s_OriginalAccountEmail_pii?: string | null
  i_AccountPhoneNumber_pii?: string | null
  company_PI_Name_Match?: string | null
  s_CompanyName?: string | null
  s_PaymentInstrumentAccountHolderName_pii?: string | null
  s_PaymentInstrumentBIN?: string | null
  s_SanitizedPaymentInstrumentIssueBank?: string | null
  s_BillingAddress_pii?: string | null
  s_PIHashIdEx?: string | null
  i_PIAge?: string | null
  d_PurchaseAmountUSD?: string | null
  i_TotalPurchaseQuantity?: string | null
  s_CatalogProductTitle_1?: string | null
  s_CatalogProductTitles?: string | null
  s_DeviceIPAddress_pii?: string | null
  s_IPGeo_Carrier?: string | null
  s_DF_screen_res?: string | null
  s_DF_browser_language?: string | null
  s_DF_os_fonts_hash?: string | null
  s_DF_time_zone?: string | null
  s_DF_browser_string?: string | null
  s_DF_device_id?: string | null
  s_fuzzy_device_id?: string | null
  s_DF_raw_ssl_signature_hash?: string | null
  s_DF_canvas_hash?: string | null
  s_MRAgent?: string | null
  s_MRDecision?: string | null
  s_MRNotes_Notes?: string | null
  s_MRNotes?: string | null
}

export enum AuditAction {
  Create = 'Create',
  Assign = 'Assign',
  RequestApprovalToSendToRisk = 'RequestApprovalToSendToRisk',
  Resolved = 'Resolved',
  ApprovedSendToRisk = 'ApprovedSendToRisk',
  ReturnToAgent = 'ReturnToAgent',
  AddNote = 'AddNote',
}

export interface BillingAccountIdEnrichmentRecord {
  /** @format date-time */
  txEventDate?: string | null
  /** @format uuid */
  riskEvaluationId?: string | null
  s_ClassicMooncakeOfferId?: string | null
  s_Entry?: string | null
  s_EventType?: string | null
  s_FinalReasonCode?: string | null
  s_FinalResultCode?: string | null
  s_Locale?: string | null
  s_ChallengePhone_pii?: string | null
  s_ChallengeCountry?: string | null
  i_RiskModelAdapScore?: string | null
  i_RiskModelScore?: string | null
  s_DeviceIPAddress_pii?: string | null
  s_DeviceIPCountryCode?: string | null
  s_IPAddress_octets3?: string | null
  s_DF_browser_language?: string | null
  s_DF_browser_string?: string | null
  s_DF_device_id?: string | null
  s_DF_fuzzy_device_id?: string | null
  s_DF_os?: string | null
  s_DF_screen_res?: string | null
  s_DF_time_zone?: string | null
  s_DF_true_ip_pii?: string | null
  s_DF_ua_browser?: string | null
  s_DF_CountryIso?: string | null
  s_DF_ua_os?: string | null
  s_AccountIdEx?: string | null
  /** @format date-time */
  dt_AccountCreatedDate?: string | null
  i_AccountPhoneNumber_pii?: string | null
  s_AccountEmail_pii?: string | null
  s_AccountEmailDomain?: string | null
  s_AccountFirstName_pii?: string | null
  s_AccountLastName_pii?: string | null
  s_AccountAddress_pii?: string | null
  s_AccountCountryCode?: string | null
  s_AltEmail_pii?: string | null
  s_AltEmailDomain?: string | null
  i_PIAge?: string | null
  s_PIHashIdEx?: string | null
  s_PaymentMethodFamily?: string | null
  s_BillingPIBinNumber?: string | null
  s_BillingPILast4?: string | null
  b_PrepaidBIN?: string | null
  s_BIN_CountryISO?: string | null
  s_BinCardType?: string | null
  /** @format date-time */
  dt_BillingPIAddDate?: string | null
  i_BillingPhoneNumber_pii?: string | null
  s_BillingAccountHolderName_pii?: string | null
  s_BillingAddress_pii?: string | null
  s_BillingCountryCode?: string | null
  s_BillingState?: string | null
  s_BillingCity?: string | null
  d_AzFraud_Behavior_Score?: string | null
  s_Behavior_Provider_Azure?: string | null
  s_AVE_SchoolParentDomain?: string | null
  s_Behavior_ProviderNotes_Azure?: string | null
  s_Behavior_HSN_Azure?: string | null
  s_Oms_AzureSubState?: string | null
  s_EmailHandleReduced_pii?: string | null
  s_PST_contact_pii?: string | null
  s_PST_email1_pii?: string | null
  s_PST_email2_pii?: string | null
  s_PST_phone1_pii?: string | null
  s_MCAPI_CRID_OrgID?: string | null
  s_MCAPI_TenantId?: string | null
}

export enum Business {
  Azure = 'Azure',
  M365 = 'M365',
  BillingAccount = 'BillingAccount',
  MSStore = 'MSStore',
  Xbox = 'Xbox',
  Other = 'Other',
}

export interface CaseAudit {
  /** @format uuid */
  id?: string
  auditAction: AuditAction
  /** @minLength 1 */
  providerName: string
  /** @format date-time */
  auditDate: string
  caseStatus: CaseStatus
  note?: string | null
  oldValues?: string | null
  newValues?: string | null
  /** @format uuid */
  caseId: string
  case?: CaseEntity
}

export interface CaseDetailPageEntity {
  /** @format uuid */
  caseId?: string
  targetId?: string | null
  assignedTo?: string | null
  caseStatus?: CaseStatus
  /** @format uuid */
  riskEvaluationId?: string | null
  initialRiskDecision?: RiskDecision
  currentRiskDecision?: RiskDecision
  /** @format int32 */
  countOfDupSubsOfEachCase?: number
  subscriptionGuidEnrichmentRecord?: SubscriptionGuidEnrichmentRecord
  billingAccountIdEnrichmentRecord?: BillingAccountIdEnrichmentRecord
  assetIdEnrichmentRecord?: AssetIdEnrichmentRecord
}

export interface CaseEntity {
  /** @format uuid */
  id?: string
  /** @minLength 1 */
  targetId: string
  /** @format date-time */
  resolvedDate?: string | null
  assignedTo?: string | null
  caseStatus: CaseStatus
  initialRiskDecision?: RiskDecision
  currentRiskDecision?: RiskDecision
  /** @format uuid */
  riskEvaluationId?: string | null
  /** @format uuid */
  actionServiceRequestId?: string | null
  /** @format uuid */
  escalationId: string
  note?: string | null
  /** @format date-time */
  updatedDateTime?: string | null
  actionStatus?: ActionStatus
  escalation?: Escalation
}

export interface CaseEntityPagedData {
  data?: CaseEntity[] | null
  /** @format int32 */
  totalCount?: number
}

export interface CasePatchForm {
  /** @format uuid */
  id: string
  /** @format date-time */
  resolvedDate?: string | null
  /**
   * @minLength 0
   * @maxLength 254
   */
  assignedTo?: string | null
  caseStatus?: CaseStatus
  actionStatus?: ActionStatus
  initialRiskDecision?: RiskDecision
  currentRiskDecision?: RiskDecision
  /** @format uuid */
  riskEvaluationId?: string | null
  note?: string | null
}

export interface CaseRequestData {
  /** @format uuid */
  escalationId: string
  targetIds: string[]
}

export enum CaseStatus {
  Created = 'Created',
  Active = 'Active',
  EnrichmentInProgress = 'EnrichmentInProgress',
  PendingSuperAgentApproval = 'PendingSuperAgentApproval',
  PendingRiskDecision = 'PendingRiskDecision',
  Closed = 'Closed',
  ErrorSendToRiskActionService = 'ErrorSendToRiskActionService',
}

export enum CaseStatusPage {
  ActiveChildCases = 'ActiveChildCases',
  PendingApproval = 'PendingApproval',
  InactiveChildCases = 'InactiveChildCases',
  ErrorChildCases = 'ErrorChildCases',
  ViewAll = 'ViewAll',
}

export enum ErrorCode {
  NoError = 'NoError',
  ValidationFailed = 'ValidationFailed',
  NotEmail = 'NotEmail',
  MissingRequiredParameter = 'MissingRequiredParameter',
  MalformedParameter = 'MalformedParameter',
  UnknownError = 'UnknownError',
}

export interface Escalation {
  /** @format uuid */
  escalationId?: string
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  name: string
  /** @minLength 1 */
  userAlias: string
  business: Business
  requestType: RequestType
  actionApprovedBy?: string | null
  /** @minLength 1 */
  requestJustification: string
  targetType: TargetType
  teamName?: string | null
  additionalContacts?: string | null
  /** @format date-time */
  deadline?: string
  /** @format date-time */
  createdOn: string
  sla: Sla
  escalationStatus: EscalationStatus
  /** @format date-time */
  closedOn?: string | null
  /** @format date-time */
  updatedDateTime?: string | null
  /** @format uuid */
  oid?: string
  caller?: string | null
  upn?: string | null
}

export interface EscalationDetailPageEntity {
  /** @format uuid */
  caseId?: string
  targetId?: string | null
  assignedTo?: string | null
  caseStatus?: CaseStatus
  currentRiskDecision?: RiskDecision
  /** @format int32 */
  countOfDupSubsOfEachCase?: number
  subscriptionGuidEnrichmentRecord?: SubscriptionGuidEnrichmentRecord
  billingAccountIdEnrichment?: BillingAccountIdEnrichmentRecord
  assetIdEnrichmentRecord?: AssetIdEnrichmentRecord
}

export interface EscalationDetailPageEntityPagedData {
  data?: EscalationDetailPageEntity[] | null
  /** @format int32 */
  totalCount?: number
}

export interface EscalationError {
  errorCode?: ErrorCode
  message?: string | null
}

export interface EscalationPatchForm {
  /** @format uuid */
  escalationId: string
  /**
   * @minLength 1
   * @maxLength 254
   */
  name?: string | null
  business?: Business
  requestType?: RequestType
  /**
   * @minLength 0
   * @maxLength 254
   */
  actionApprovedBy?: string | null
  /**
   * @minLength 1
   * @maxLength 4000
   */
  requestJustification?: string | null
  targetType?: TargetType
  /**
   * @minLength 1
   * @maxLength 60
   */
  teamName?: string | null
  /**
   * @minLength 1
   * @maxLength 254
   */
  additionalContacts?: string | null
  /** @format date-time */
  deadline?: string | null
  escalationStatus?: EscalationStatus
  /** @format date-time */
  closedOn?: string | null
}

export interface EscalationRequestForm {
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /**
   * @minLength 1
   * @maxLength 254
   */
  name: string
  /**
   * @minLength 1
   * @maxLength 254
   */
  userAlias: string
  business: Business
  requestType: RequestType
  /**
   * @minLength 0
   * @maxLength 254
   */
  actionApprovedBy?: string | null
  /**
   * @minLength 1
   * @maxLength 4000
   */
  requestJustification: string
  targetType: TargetType
  targetIds: string[]
  /**
   * @minLength 1
   * @maxLength 60
   */
  teamName: string
  /**
   * @minLength 0
   * @maxLength 254
   */
  additionalContacts?: string | null
  escalationStatus?: EscalationStatus
  /** @format date-time */
  deadline?: string
}

export interface EscalationResource {
  /** @format uuid */
  escalationId?: string
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /**
   * @minLength 1
   * @maxLength 254
   */
  name: string
  /**
   * @minLength 1
   * @maxLength 254
   */
  userAlias: string
  business: Business
  requestType: RequestType
  /**
   * @minLength 0
   * @maxLength 254
   */
  actionApprovedBy?: string | null
  /**
   * @minLength 1
   * @maxLength 4000
   */
  requestJustification: string
  targetType: TargetType
  /**
   * @minLength 1
   * @maxLength 60
   */
  teamName?: string | null
  /**
   * @minLength 1
   * @maxLength 254
   */
  additionalContacts?: string | null
  /** @format date-time */
  deadline?: string
  escalationStatus: EscalationStatus
  /** @format date-time */
  closedOn?: string | null
  /** @format uuid */
  oid?: string
  caller?: string | null
  upn?: string | null
}

export enum EscalationStatus {
  Created = 'Created',
  Active = 'Active',
  Closed = 'Closed',
}

export enum EscalationStatusPage {
  EscalationsActive = 'EscalationsActive',
  EscalationsMyAssigned = 'EscalationsMyAssigned',
  EscalationsViewAll = 'EscalationsViewAll',
  MySubmittedActive = 'MySubmittedActive',
  MySubmittedClosed = 'MySubmittedClosed',
  MySubmittedViewAll = 'MySubmittedViewAll',
  PendingApproval = 'PendingApproval',
}

export interface EscalationStatusPageEntity {
  /** @format uuid */
  escalationId?: string
  /** @format int32 */
  childCount?: number
  contact?: string | null
  priority?: Sla
  status?: EscalationStatus
  slaStatus?: SlaStatus
  /** @format date-time */
  deadline?: string
  /** @format date-time */
  createdOn?: string
}

export interface EscalationStatusPageEntityPagedData {
  data?: EscalationStatusPageEntity[] | null
  /** @format int32 */
  totalCount?: number
}

export interface NoteEntity {
  /** @format uuid */
  caseId?: string
  providerName?: string | null
  /** @format date-time */
  auditDate?: string
  note?: string | null
}

export interface ProblemDetails {
  type?: string | null
  title?: string | null
  /** @format int32 */
  status?: number | null
  detail?: string | null
  instance?: string | null
  [key: string]: any
}

export enum RequestType {
  Investigation = 'Investigation',
  InvestigationAndAction = 'InvestigationAndAction',
  ActionOnly = 'ActionOnly',
}

export enum RiskDecision {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum Sla {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum SlaStatus {
  OutOfSla = 'OutOfSla',
  NearingSla = 'NearingSla',
  InSla = 'InSla',
}

export interface SubscriptionGuidEnrichmentRecord {
  /** @format date-time */
  txEventDate?: string | null
  subscriptionGuid?: string | null
  /** @format uuid */
  riskEvaluationId?: string | null
  offerName?: string | null
  s_ClassicMooncakeOfferId?: string | null
  s_Entry?: string | null
  s_EventType?: string | null
  s_FinalReasonCode?: string | null
  s_FinalResultCode?: string | null
  s_Locale?: string | null
  s_ChallengePhone_pii?: string | null
  challengeCountry?: string | null
  i_RiskModelAdapScore?: string | null
  i_RiskModelScore?: string | null
  s_DeviceIPAddress_pii?: string | null
  s_DeviceIPCountryCode?: string | null
  s_IPAddress_octets3?: string | null
  s_DF_browser_language?: string | null
  s_DF_browser_string?: string | null
  s_DF_device_id?: string | null
  s_DF_fuzzy_device_id?: string | null
  s_DF_os?: string | null
  s_DF_screen_res?: string | null
  s_DF_time_zone?: string | null
  s_DF_true_ip_pii?: string | null
  s_DF_ua_browser?: string | null
  s_DF_CountryIso?: string | null
  s_DF_ua_os?: string | null
  puid?: string | null
  s_AccountIdEx?: string | null
  /** @format date-time */
  dt_AccountCreatedDate?: string | null
  i_AccountPhoneNumber_pii?: string | null
  s_AccountEmail_pii?: string | null
  s_AccountEmailDomain?: string | null
  s_AccountFirstName_pii?: string | null
  s_AccountLastName_pii?: string | null
  s_AccountAddress_pii?: string | null
  s_AccountCountryCode?: string | null
  s_AltEmail_pii?: string | null
  s_AltEmailDomain?: string | null
  i_PIAge?: string | null
  s_PIHashIdEx?: string | null
  s_PaymentMethodFamily?: string | null
  s_BillingPIBinNumber?: string | null
  s_BillingPILast4?: string | null
  b_PrepaidBIN?: string | null
  s_BIN_CountryISO?: string | null
  s_BinCardType?: string | null
  /** @format date-time */
  dt_BillingPIAddDate?: string | null
  i_BillingPhoneNumber_pii?: string | null
  s_BillingAccountHolderName_pii?: string | null
  s_BillingAddress_pii?: string | null
  s_BillingCountryCode?: string | null
  s_BillingState?: string | null
  s_BillingCity?: string | null
  d_AzFraud_Behavior_Score?: string | null
  s_Behavior_Provider_Azure?: string | null
  /** @format int32 */
  isFraud?: number | null
  /** @format date-time */
  isFraudDate?: string | null
  /** @format double */
  usdChargeBackAmount?: number | null
  /** @format double */
  usdLifeTimeAmountBalanceNotPaid?: number | null
  /** @format double */
  usdLifeTimeAmountPaid?: number | null
  /** @format double */
  usdAmountInDunning?: number | null
  /** @format double */
  usdAmountWriteOffByFraud?: number | null
  /** @format double */
  usdAmountWriteOffByDunning?: number | null
  azureSubscriptionState?: string | null
  /** @format int32 */
  gsM_IsReactivation?: number | null
  /** @format date-time */
  gsM_IsReactivation_ReactivationDate?: string | null
  /** @format date-time */
  latestFraudEventDate?: string | null
  isInternalTest?: string | null
  commerceAccountId?: string | null
  s_AVE_SchoolParentDomain?: string | null
  /** @format int32 */
  chargeBackCount?: number | null
  /** @format int32 */
  chargeBackReversalCount?: number | null
  /** @format double */
  usdChargeBackReversalAmount?: number | null
  channelType?: string | null
  entitlementState?: string | null
  entitlementSuspensionReason?: string | null
  fullStatusChain?: string | null
  /** @format double */
  usdLifeTimeAmountPurchased?: number | null
  mostRecentOrderPaymentStatus?: string | null
  /** @format date-time */
  mostRecentInvoiceDate?: string | null
  /** @format date-time */
  subscriptionCreatedDateTime?: string | null
  fraudDataSource?: string | null
  /** @format date-time */
  firstFraudEventDate?: string | null
  /** @format date-time */
  latestActiveEventDate?: string | null
  /** @format date-time */
  provisioningCreatedDateTime?: string | null
  marketingCategory?: string | null
  s_Behavior_ProviderNotes_Azure?: string | null
  s_Behavior_HSN_Azure?: string | null
  s_Oms_AzureSubState?: string | null
  s_EmailHandleReduced_pii?: string | null
  billingType?: string | null
  paymentType?: string | null
  s_PST_contact_pii?: string | null
  s_PST_email1_pii?: string | null
  s_PST_email2_pii?: string | null
  s_PST_phone1_pii?: string | null
  s_PST_login1?: string | null
}

export enum TargetType {
  SubscriptionGuid = 'SubscriptionGuid',
  AssetId = 'AssetId',
  BillingAccountCAID = 'BillingAccountCAID',
  BillingAccountCRIDORGID = 'BillingAccountCRIDORGID',
  Other = 'Other',
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ''
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title EscalationApi
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Case
     * @name CaseSendToRiskCreate
     * @summary Send selected Cases to Action Service Service Bus.
     * @request POST:/api/case/send-to-risk
     * @secure
     */
    caseSendToRiskCreate: (data: string[], params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/case/send-to-risk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Case
     * @name CaseSendAllToRiskCreate
     * @summary Send all Cases to Action Service Service Bus from escalations.
     * @request POST:/api/case/send-all-to-risk
     * @secure
     */
    caseSendAllToRiskCreate: (data: string[], params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/case/send-all-to-risk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Case
     * @name CaseReturnToAgentCreate
     * @summary Send All cases from escalation list back to agent.
     * @request POST:/api/case/return-to-agent
     * @secure
     */
    caseReturnToAgentCreate: (data: string[], params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/case/return-to-agent`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Case
     * @name CaseCreate
     * @summary Create Case.
     * @request POST:/api/case
     * @secure
     */
    caseCreate: (data: CaseRequestData, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/case`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Case
     * @name CaseList
     * @summary Get cases with certain filter.
     * @request GET:/api/case
     * @secure
     */
    caseList: (
      query?: {
        /** @format int32 */
        Count?: number
        /** @format int32 */
        Offset?: number
        /** @format date-time */
        ResolvedDateRangeStart?: string
        /** @format date-time */
        ResolvedDateRangeEnd?: string
        ActionStatus?: ActionStatus
        AssignedTo?: string
        CaseStatus?: CaseStatus
        InitialRiskDecision?: RiskDecision
        CurrentRiskDecision?: RiskDecision
        /** @format uuid */
        EscalationId?: string
        /** @format uuid */
        RiskEvaluationId?: string
        /** @format uuid */
        ActionServiceRequestId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseEntityPagedData, ProblemDetails>({
        path: `/api/case`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Case
     * @name CasesPartialUpdate
     * @summary Update multiple cases by casePatchForm.
     * @request PATCH:/api/case
     * @secure
     */
    casesPartialUpdate: (data: CasePatchForm[], params: RequestParams = {}) =>
      this.request<CaseEntity[], ProblemDetails>({
        path: `/api/case`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Case
     * @name CaseDetail
     * @summary Get case details by PK caseId.
     * @request GET:/api/case/{caseId}
     * @secure
     */
    caseDetail: (caseId: string, params: RequestParams = {}) =>
      this.request<CaseEntity, ProblemDetails>({
        path: `/api/case/${caseId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Case
     * @name CasePartialUpdate
     * @summary Update one case by casePatchForm.
     * @request PATCH:/api/case/{caseId}
     * @secure
     */
    casePartialUpdate: (caseId: string, data: CasePatchForm, params: RequestParams = {}) =>
      this.request<CaseEntity, ProblemDetails>({
        path: `/api/case/${caseId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Case
     * @name CaseDetailByCaseId
     * @summary Get case detail by caseId.
     * @request GET:/api/case/{caseId}/detail
     * @secure
     */
    caseDetailByCaseId: (caseId: string, params: RequestParams = {}) =>
      this.request<CaseDetailPageEntity, ProblemDetails>({
        path: `/api/case/${caseId}/detail`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Case
     * @name CaseTargetIdDetail
     * @summary Get latest enrichment for a case by targetId.
     * @request GET:/api/case/targetId/{targetId}
     * @secure
     */
    caseTargetIdDetail: (targetId: string, params: RequestParams = {}) =>
      this.request<SubscriptionGuidEnrichmentRecord, ProblemDetails>({
        path: `/api/case/targetId/${targetId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseAudit
     * @name CaseAuditList
     * @summary GetCaseAudits by given filter.
     * @request GET:/api/case-audit
     * @secure
     */
    caseAuditList: (
      query?: {
        /** @format int32 */
        Count?: number
        /** @format int32 */
        Offset?: number
        /** @format uuid */
        CaseAuditId?: string
        /** @format uuid */
        CaseId?: string
        /** @format date-time */
        AuditDateRangeStart?: string
        /** @format date-time */
        AuditDateRangeEnd?: string
        CaseStatus?: CaseStatus
        AuditAction?: AuditAction
        ProviderName?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseAudit[], ProblemDetails>({
        path: `/api/case-audit`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseAudit
     * @name CaseAuditGetNotesList
     * @summary Get all notes related with the same case.
     * @request GET:/api/case-audit/get-notes
     * @secure
     */
    caseAuditGetNotesList: (
      query?: {
        /**
         * primary key of the cases table.
         * @format uuid
         */
        caseId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<NoteEntity[], ProblemDetails>({
        path: `/api/case-audit/get-notes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseAudit
     * @name CaseAuditGetHistoryStatusList
     * @summary Get history status by CaseId.
     * @request GET:/api/case-audit/get-history-status
     * @secure
     */
    caseAuditGetHistoryStatusList: (
      query?: {
        /**
         * caseId.
         * @format uuid
         */
        caseId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<CaseStatus[], ProblemDetails>({
        path: `/api/case-audit/get-history-status`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enrichment
     * @name EnrichmentSendCreateToServicebusCreate
     * @summary Send create enrichment message to service bus.
     * @request POST:/api/enrichment/send-create-to-servicebus/{escalationId}
     * @secure
     */
    enrichmentSendCreateToServicebusCreate: (escalationId: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/enrichment/send-create-to-servicebus/${escalationId}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enrichment
     * @name EnrichmentSendReenrichToServicebusCreate
     * @summary Send re-enrich message to service bus method.
     * @request POST:/api/enrichment/send-reenrich-to-servicebus
     * @secure
     */
    enrichmentSendReenrichToServicebusCreate: (data: string[], params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/enrichment/send-reenrich-to-servicebus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enrichment
     * @name EnrichmentEnrichmentsDetail
     * @summary Get all enrichments by an escalationId.
     * @request GET:/api/enrichment/{escalationId}/enrichments
     * @secure
     */
    enrichmentEnrichmentsDetail: (escalationId: string, params: RequestParams = {}) =>
      this.request<SubscriptionGuidEnrichmentRecord[], ProblemDetails>({
        path: `/api/enrichment/${escalationId}/enrichments`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enrichment
     * @name EnrichmentEnrichmentsv2Detail
     * @summary Get all enrichments by an escalationId.
     * @request GET:/api/enrichment/{escalationId}/enrichmentsv2
     * @secure
     */
    enrichmentEnrichmentsv2Detail: (escalationId: string, params: RequestParams = {}) =>
      this.request<SubscriptionGuidEnrichmentRecord[], ProblemDetails>({
        path: `/api/enrichment/${escalationId}/enrichmentsv2`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Escalation
     * @name CreateEscalation
     * @summary PostEscalation with request details.
     * @request POST:/api/escalation/create-escalation
     * @secure
     */
    createEscalation: (data: EscalationRequestForm, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/escalation/create-escalation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Escalation
     * @name EscalationList
     * @summary GetEscalation.
     * @request GET:/api/escalation
     * @secure
     */
    escalationList: (
      query?: {
        /** @format uuid */
        Id?: string
        UserAlias?: string
        Status?: EscalationStatus
        RequestType?: RequestType
        TeamName?: string
        /** @format int32 */
        Count?: number
        /** @format int32 */
        Offset?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<Escalation[], ProblemDetails>({
        path: `/api/escalation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Escalation
     * @name EscalationPartialUpdate
     * @summary PatchEscalations.
     * @request PATCH:/api/escalation
     * @secure
     */
    escalationPartialUpdate: (data: EscalationPatchForm[], params: RequestParams = {}) =>
      this.request<EscalationResource[], EscalationError | ProblemDetails>({
        path: `/api/escalation`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Escalation
     * @name EscalationGetStatusPageEntitiesDetail
     * @summary GetEscalations for different pages and tabs.
     * @request GET:/api/escalation/get-status-page-entities/{escalationStatusPage}
     * @secure
     */
    escalationGetStatusPageEntitiesDetail: (
      escalationStatusPage: EscalationStatusPage,
      query?: {
        /** userAlias. */
        userAlias?: string
        /**
         * offset.
         * @format int32
         */
        offset?: number
        /**
         * count.
         * @format int32
         */
        count?: number
        /**
         * escalationId.
         * @format uuid
         */
        escalationId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<EscalationStatusPageEntityPagedData, ProblemDetails>({
        path: `/api/escalation/get-status-page-entities/${escalationStatusPage}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Escalation
     * @name EscalationDetail
     * @summary GetEscalationbyId.
     * @request GET:/api/escalation/{id}
     * @secure
     */
    escalationDetail: (id: string, params: RequestParams = {}) =>
      this.request<Escalation, ProblemDetails>({
        path: `/api/escalation/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Escalation
     * @name EscalationDetailByCaseStatusPage
     * @summary GetEscalationDetail with latest enrichmentdata by escalationId and differnent tabs base on case status.
     * @request GET:/api/escalation/{escalationId}/{caseStatusPage}
     * @secure
     */
    escalationDetailByCaseStatusPage: (
      escalationId: string,
      caseStatusPage: CaseStatusPage,
      query?: {
        /**
         * offset.
         * @format int32
         */
        offset?: number
        /**
         * count.
         * @format int32
         */
        count?: number
        /**
         * caseId.
         * @format uuid
         */
        caseId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<EscalationDetailPageEntityPagedData, ProblemDetails>({
        path: `/api/escalation/${escalationId}/${caseStatusPage}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Escalation
     * @name EscalationDownloadDetail
     * @summary Download EscalationDetail with all enrichment data by escalationId and differnent tab base on case status.
     * @request GET:/api/escalation/download/{escalationId}/{caseStatusPage}
     * @secure
     */
    escalationDownloadDetail: (escalationId: string, caseStatusPage: CaseStatusPage, params: RequestParams = {}) =>
      this.request<EscalationDetailPageEntity[], ProblemDetails>({
        path: `/api/escalation/download/${escalationId}/${caseStatusPage}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
