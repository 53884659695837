import { AccountInfo } from '@azure/msal-common'
import React, { createContext, useState } from 'react'

interface IAppContext {
  activeUser: AccountInfo | null
  setActiveUser: (accountInfo: AccountInfo | null) => void
  isSidebarCollapsed: boolean
  setSidebarCollapsed: (sidebarCollapsed: boolean) => void
}

const defaultValue: IAppContext = {
  activeUser: null,
  setActiveUser: (accountInfo) => {},
  isSidebarCollapsed: false,
  setSidebarCollapsed: () => {},
}

export const AppContext = createContext<IAppContext>(defaultValue)

const AppContextProvider: React.FunctionComponent = ({ children }) => {
  const [activeUser, _setActiveUser] = useState<AccountInfo | null>(null)
  const [isSidebarCollapsed, _setSidebarCollapsed] = useState<boolean>(false)

  const setActiveUser = (user: AccountInfo | null) => {
    _setActiveUser(user)
  }

  const setSidebarCollapsed = (sidebarCollapsed: boolean) => {
    _setSidebarCollapsed(sidebarCollapsed)
  }

  return (
    <AppContext.Provider
      value={{
        activeUser,
        setActiveUser,
        isSidebarCollapsed,
        setSidebarCollapsed,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
