import { PublicClientApplication } from '@azure/msal-browser'
import { BaseEvents, EventEmitter } from '../eventEmitter/eventEmitter'
import DummyApiService from './dummyApiService'
import EscalationApiService from './escalationApiService'
import { IEscalationApiService } from './IEscalationApiService'

export default class EscalationApiServiceFactory {
  private static _instance: IEscalationApiService

  static {
    if (process.env.REACT_APP_API_DUMMY === 'true') {
      this._instance = new DummyApiService()
    } else {
      this._instance = new EscalationApiService()
    }
  }

  static initializate(
    publicClientApplication: PublicClientApplication,
    eventEmitter: EventEmitter<BaseEvents>
  ) {
    this._instance.initialize(publicClientApplication, eventEmitter)
  }

  static getInstance(): IEscalationApiService {
    return this._instance
  }
}
