import {
  FocusTrapZone,
  Layer,
  Link,
  mergeStyleSets,
  Popup,
} from '@fluentui/react'

const popupStyles = mergeStyleSets({
  content: {
    background: 'white',
    fontSize: '24px',
    left: '50%',
    padding: '0 2em 2em',
    position: 'absolute',
    top: '20%',
    transform: 'translate(-50%, -50%)',
  },
})

const Decommission: React.FunctionComponent = () => {
  return (
    <>
      <Layer>
        <Popup role="dialog" aria-modal="true">
          <FocusTrapZone>
            <div role="document" className={popupStyles.content}>
              <div>The IEP portal is replaced with an IcM based process.</div>
              <div>
                Please refer to this document{' '}
                <Link
                  href="https://aka.ms/RiskIcMEscalation"
                  underline
                  autoFocus={false}
                >
                  https://aka.ms/RiskIcMEscalation
                </Link>{' '}
                and create the IcM for your escalation.
              </div>
              <div>
                If you are from CRCE please continue to use the SendToRisk
                feature in Apollo/JX.
              </div>
            </div>
          </FocusTrapZone>
        </Popup>
      </Layer>
    </>
  )
}

export default Decommission
