import { Client } from '@microsoft/microsoft-graph-client'
import { PublicClientApplication, InteractionType } from '@azure/msal-browser'
import {
  AuthCodeMSALBrowserAuthenticationProvider,
  AuthCodeMSALBrowserAuthenticationProviderOptions,
} from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'
import { graphScopes } from '../auth/authConfig'
import { BaseEvents, EventEmitter } from '../eventEmitter/eventEmitter'
import { User } from 'microsoft-graph'

export default class GraphService {
  private static options: AuthCodeMSALBrowserAuthenticationProviderOptions
  private static authProvider: AuthCodeMSALBrowserAuthenticationProvider
  private static client: Client
  private static eventEmitter: EventEmitter<BaseEvents>

  private constructor() {}

  static initializate(
    publicClientApplication: PublicClientApplication,
    eventEmitter: EventEmitter<BaseEvents>
  ) {
    this.options = {
      account: publicClientApplication.getActiveAccount()!,
      interactionType: InteractionType.Redirect,
      scopes: graphScopes,
    }

    this.authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      publicClientApplication,
      this.options
    )

    this.client = Client.initWithMiddleware({
      authProvider: this.authProvider,
    })

    this.eventEmitter = eventEmitter
  }

  // Scope: User.Read
  static async getMe() {
    const response = await this.client.api('/me').get()
    const user = response as User
    return user
  }

  // Scope: User.ReadBasic.All
  static async getUsersByDisplayName(displayNamePart: string) {
    const response = await this.client
      .api('/users')
      .header('ConsistencyLevel', 'eventual')
      .count()
      .filter("endsWith(mail,'@microsoft.com')")
      .search(`"displayName:${displayNamePart}"`)
      .select('id,displayName,mail')
      .orderby('displayName')
      .get()
    const users = response.value as User[]
    return users
  }
}
